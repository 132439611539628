import React, { Component } from "react";
import MainPageTransactionItem from "./MainPageTransactionItem";
import { Translate, withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import Preloader from "../Preloader";
import "./index.scss";

class MainPageTransactionList extends Component {
	state = {
		tx: {
			action: "transfer",
			isFrom: true,
			height: 0,
			hash: "",
			timestamp: "",
			sender: "",
			recipient: "",
			amount: 0,
			paramining: 0,

			message: "",
		},
	};

	onTxShow = (tx) => {
		const events = {};

		for (let event of tx.logs[0].events) {
			let attrs = {};

			for (let attr of event.attributes) {
				if (attrs[attr.key] === undefined) {
					attrs[attr.key] = attr.value;
				} else {
					if (
						event.type === "withdraw_rewards" &&
						attr.key === "amount" &&
						attr.value !== undefined
					) {
						attrs["amount"] = parseInt(attrs["amount"]) + parseInt(attr.value);
					}
				}
			}
			if (event.type === "withdraw_rewards") {
				const totalRewardsAmount = tx.logs.reduce((acc, curr) => {
					for (const event of curr.events) {
						if (event.type === "withdraw_rewards") {
							const amount = event.attributes.find(
								(item) => item.key === "amount",
							);
							if (amount && amount.value) {
								return (acc += parseInt(amount.value));
							}
						}
					}
				}, 0);
				attrs.amount = totalRewardsAmount;
			}
			events[event.type] = attrs;
		}

		const timestamp = tx.timestamp;
		const { message, posmining_charged, transfer, withdraw_rewards, delegate } =
			events;
		const { sender } = message;
		const isFrom = sender === this.props.account.owner;

		let action = message.action;

		let amount = 0;

		if (withdraw_rewards !== undefined) {
			action = "withdraw_delegator_reward";
			amount = withdraw_rewards.amount;
		} else if (delegate !== undefined) {
			action = "delegate";
			amount = delegate.amount;
		} else {
			if (message.action === "reinvest") {
				amount = message.amount;
			} else {
				const msg = tx.tx.value.msg[0].value;

				amount = msg.amount[0].amount;
			}
		}

		this.setState({
			tx: {
				action: action,
				isFrom: isFrom,
				height: tx.height,
				hash: tx.txhash,
				timestamp: timestamp,
				recipient: isFrom && transfer ? transfer.recipient : "",
				sender: sender,
				amount: amount,
				paramining:
					isFrom && posmining_charged !== undefined
						? posmining_charged.amount
						: 0,
				message: message,
			},
		});
	};

	render() {
		const { txs, account, pending, error } = this.props;

		const transactions = txs.results ? txs.results.slice(0, 3) : [];
		return (
			<>
				<h2>
					<Translate id="transaction.list" />
				</h2>
				{pending ? (
					<Preloader size={"bg"} />
				) : (
					<>
						{transactions ? (
							transactions.map((item) => (
								<MainPageTransactionItem
									key={item.height}
									onTxShow={this.onTxShow}
									rate={this.props.rate}
									account={account}
									tx={item}
								/>
							))
						) : (
							<div className="main-page-error-msg">
								<Translate id={"common.error_loading_data"} />
							</div>
						)}
					</>
				)}

				<Link to={"/transactions"} className="transaction-button">
					<Translate id="transaction.see" />
				</Link>
				<div className="clear" />
			</>
		);
	}
}

export default withLocalize(MainPageTransactionList);