export const NODE_ENDPOINT = "https://rest.ouroboros-crypto.com";
export const NODE_ENDPOINT_NEW = "https://microservice.ouroboros-crypto.com";
export const RPC_ENDPOINT = "https://rpc.ouroboros-crypto.com";

export const NODE_ENDPOINT_HOT = `https://new-api.ouroboros-crypto.com`;

export const API_ENDPOINT = "https://api.ouroboros-crypto.com";
export const EXPLORER = "https://explorer.ouroboros-crypto.com";

export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";

export const SAVINGS = [
	0,
	1.5, // 1.50 или 50%, 30-60 дней
	1.51, // 1.51 или 51%, 60-90 дней
	1.52, // 1.52 или 52%, 90-120 дней
	1.53, // 1.53 или 53%, 120-150 дней
	1.54, // 1.54 или 54%, 150-180 дней
	1.55, // 1.55 или 55%, 180-210 дней
	1.55, // 1.55 или 55%, 210-240 дней
	1.55, // 1.55 или 55%, 240-270 дней
	1.55, // 1.55 или 55%, 270-300 дней
	1.55, // 1.55 или 55%, 300-330 дней
	1.55, // 1.55 или 55%, 330-360 дней
	2.0, // 2.00 или 100%, >360 дней
];

export const GOOGLE_RECAPTCHA = "6Lel4sAUAAAAADDOXrPbbOT6AQCYvcOzCPGIR7SQ";