import { NODE_ENDPOINT } from "../settings";
import {
	validatorsUpdatePending,
	validatorsUpdateSuccess,
} from "../actions/validators";
import convertCoins from "../utils/coins";

function compare(a, b) {
	if (parseInt(a.delegated, 10) < parseInt(b.delegated, 10)) {
		return 1;
	}

	if (parseInt(a.delegated, 10) > parseInt(b.delegated, 10)) {
		return -1;
	}

	if (a.jailed !== b.jailed) {
		return a.jailed ? 1 : -1;
	}

	return 0;
}

// Вытаскиваем список транзакций юзера
export default function fetchValidatorsAction(address) {
	return (dispatch, getState) => {
		// Мы в процессе загрузки данных
		dispatch(validatorsUpdatePending());

		return fetch(NODE_ENDPOINT + "/cosmos/staking/v1beta1/validators")
			.then((res) => res.json()
			)
			.then((data) => {
			  console.log('VALIDATORS');
			  console.log(data);
				const validators = data["validators"];

				return fetch(NODE_ENDPOINT + "/cosmos/staking/v1beta1/delegations/" + address)
					.then((res) => res.json())
					.then((delegations) => {
					  console.log('DELEGATIONS');
            console.log(delegations);
						let delegationsResult = {};

						let total = 0;

						delegations["delegation_responses"].map((value, key) => {
						  console.log('degrorespn');
						  console.log(value);

						  const del = value['delegation'];
							delegationsResult[del["validator_address"]] = {'amount': parseInt(del["shares"])};

							total += convertCoins(parseInt(del["shares"]));

							return true;
						});

						console.log('delegation results', delegationsResult);

						validators.map((validator, key) => {
							if (
								delegationsResult[validator["operator_address"]] !== undefined
							) {
								validators[key]["delegated"] =
									delegationsResult[validator["operator_address"]];
							} else {
								validators[key]["delegated"] = "0";
							}

							return true;
						});

						validators.sort(compare);

						console.log('final validators', validators);

						dispatch(validatorsUpdateSuccess(validators, total));

						return validators;
					});
			})

			.catch((error) => {
				console.log(error);
			});
	};
}