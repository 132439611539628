import {
	TX_SEND_SUCCESS,
	TX_FETCH_SUCCESS,
	TX_FETCH_PENDING,
	TX_FETCH_ERROR,
	TX_SEND_PENDING,
	TX_SEND_ERROR,
	TX_SEND_MNEMO_PENDING,
	TX_SEND_MNEMO_SUCCESS,
	TX_SEND_MNEMO_ERROR,
	TRANSACTIONS_FETCH_ERROR,
	TRANSACTIONS_FETCH_PENDING,
	TRANSACTIONS_FETCH_SUCCESS,
	SET_SELECT_COIN,
} from "../actions/tx";

export const INITIAL_STATE = {
	Txs: {
		results: [],
		limit: 0,
		total: 0,
		pages: 1,
		page: 1,
	},
	transactions: {},
	last_hash: "",
	error: "",
	pending: false,
	selectedCoin: "OURO",
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case TRANSACTIONS_FETCH_PENDING:
			return {
				...state,
				pending: true,
			};
		case TRANSACTIONS_FETCH_SUCCESS:
			return {
				...state,
				transactions: action.data,
			};
		case TRANSACTIONS_FETCH_ERROR:
			return {
				...state,
				error: action.error,
				pending: false,
			};
		case TX_SEND_SUCCESS:
			return {
				...state,
				last_hash: action.data.txhash,
				error: "",
				pending: false,
			};
		case TX_FETCH_SUCCESS:
			return {
				...state,
				Txs: action.data,
				pending: false,
			};
		case TX_FETCH_PENDING:
			return {
				...state,
				pending: true,
			};
		case TX_FETCH_ERROR:
			return {
				...state,
				error: action.error,
				pending: false,
			};
		case TX_SEND_PENDING:
			return {
				...state,
				pending: true,
			};
		case TX_SEND_ERROR:
			return {
				...state,
				error: action.error,
				pending: false,
			};
		case TX_SEND_MNEMO_PENDING:
			return {
				...state,
				error: "",
				padding: true,
			};
		case TX_SEND_MNEMO_SUCCESS:
			return {
				...state,
				last_hash: action.transactionHash,
				padding: false,
			};
		case TX_SEND_MNEMO_ERROR:
			return {
				...state,
				error: action.error,
				padding: false,
			};
		case SET_SELECT_COIN:
			return {
				...state,
				selectedCoin: action.payload,
			};
	}

	return state;
}