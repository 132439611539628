import {
	txSendReinvestPending,
	txSendReinvestSuccess,
	txSendReinvestError,
} from "../actions/reinvest";
import {
	generateAccountFromMnemonic,
	generatePushableTransaction,
} from "../utils/crypto";
import * as blockchain from "../utils/blockchain";

import { broadcastTx } from "../utils/blockchain";
const { DirectSecp256k1HdWallet } = require("@cosmjs/proto-signing");
const { fromBase64, toBase64 } = require("@cosmjs/encoding");
const {
	StargateClient,
	SigningStargateClient,
	assertIsBroadcastTxSuccess,
} = require("@cosmjs/stargate");
const axios = require("axios");
const crypto = require("crypto");
const {
	makeSignDoc,
	serializeSignDoc,
	Secp256k1HdWallet,
} = require("@cosmjs/amino");

const {
	TxBody,
	AuthInfo,
	TxRaw,
} = require("cosmjs-types/cosmos/tx/v1beta1/tx");
const settings = require("../settings");

const NODE_ENDPOINT = settings.NODE_ENDPOINT;
const RPC_ENDPOINT = settings.RPC_ENDPOINT;

// We need to register the custom message type
const { Registry } = require("@cosmjs/proto-signing");
const { MsgReinvest } = require("../proto/tx");

const registry = new Registry();

registry.register("/node.node.MsgReinvest", MsgReinvest);

const chainId = "ouroboros";
const denom = "ouro";

const CHAIN_ID = "ouroboros";

async function handleTx(user) {
	const wallet = await DirectSecp256k1HdWallet.fromMnemonic(user.mnemonic, {
		prefix: "ouro",
	});
	const [account] = await wallet.getAccounts();

	const client = await SigningStargateClient.connectWithSigner(
		RPC_ENDPOINT,
		wallet,
		{
			registry,
		},
	);
	const FEE = {
		amount: [{ denom: "ouro", amount: "10000" }],
		gas: "95000000000",
	};
	const CUSTOM_MSG_TYPE = "/node.node.MsgReinvest";
	const CUSTOM_MSG = {
		sender: account.address,
		coin: "ouro",
	};

	const msg = {
		typeUrl: CUSTOM_MSG_TYPE,
		value: CUSTOM_MSG,
	};

	const accountResponse = await axios.get(
		`${NODE_ENDPOINT}/cosmos/auth/v1beta1/accounts/${account.address}`,
	);
	const accountInfo = accountResponse.data.account;
	const accountNumber = parseInt(accountInfo.account_number, 10);
	const sequence = parseInt(accountInfo.sequence, 10);

	const SENDER_ADDRESS = account.address;

	const gasLimit = 200000;
	const feeAmount = {
		amount: [
			{
				denom: "ouro",
				amount: "2000", // Fee in smallest denomination
			},
		],
		gas: gasLimit.toString(),
	};

	const msgAny = {
		typeUrl: "/node.node.MsgReinvest",
		value: {
			creator: account.address,
			coin: "ouro",
		},
	};

	// Step 4: Sign and broadcast the transaction
	const tx = await client.signAndBroadcast(
		account.address,
		[msgAny],
		feeAmount,
		"Reinvest",
	);

	console.log("Transaction successful:", tx.transactionHash);

	return tx;
}

export default function sendReinvest(user) {
	return (dispatch, getState) => {
		dispatch(txSendReinvestPending());

		return handleTx(user).then((tx) => {
			dispatch(txSendReinvestSuccess());
		});
	};
}