//
const fetch = require("node-fetch");

const settings = require("../settings");

const NODE_ENDPOINT = settings.NODE_ENDPOINT;

// Get all the account info by the address
exports.getAccountInfo = function (address) {
	return fetch(`${NODE_ENDPOINT}/cosmos/auth/v1beta1/accounts/${address}`)
		.then((res) => res.json())
		.then((data) => data.account);
};

// Get the balance of the account by its address using getAccountInfo
exports.getBalance = function (address) {
	return fetch(
		`${NODE_ENDPOINT}/cosmos/bank/v1beta1/balances/${address}/by_denom?denom=ouro`,
	)
		.then((res) => res.json())
		.then((data) => {
			if (data.error) {
				throw data.error;
			}

			if (data.balance.amount == "0") {
				return 0;
			}

			return parseInt(data.balance.amount) / 1e6;
		});
};

// Get the latest "mined" block
exports.getLatestBlock = function () {
	return fetch(`${NODE_ENDPOINT}/cosmos/base/tendermint/v1beta1/blocks/latest`)
		.then((res) => res.json())
		.then((data) => {
			return parseInt(data.block.header.height);
		});
};

// Get all the transaction details by its hash
exports.getTransactionDetails = function (hash) {
	return fetch(`${NODE_ENDPOINT}/cosmos/tx/v1beta1/txs/${hash}`).then((res) =>
		res.json(),
	);
};

// Get all the transactions were sent by the sender
exports.getTransactionsFromAddress = function (sender) {
	return fetch(`${NODE_ENDPOINT}/txs?message.sender=${sender}&limit=100`).then(
		(res) => res.json(),
	);
};

// Broadcast the TX object and return the response
exports.broadcastTx = function (tx) {
	return fetch(`${NODE_ENDPOINT}/txs`, {
		method: "post",
		headers: {
			"Content-type": "application/json",
			Accept: "application/json",
			"Accept-Charset": "utf-8",
		},
		body: JSON.stringify(tx),
	}).then((res) => res.json());
};