import React, {Component} from 'react';
import Moment from 'react-moment';
import {Translate, withLocalize} from "react-localize-redux";
import convertCoins, {formatCoins, convertFormatCoinsToUsd} from "../../utils/coins";
import {ALL, FROM_ADDRESS, PARAMINING, REINVEST, TO_ADDRESS} from "../../constants/common";

class TransactionItem extends Component {
    render() {
        const {tx, account, rate, displayType} = this.props;
        const { timestamp, sender, type } = tx;

        const isFrom = sender === account.owner;
        const isReceived = tx.recipient === account.owner;
        const fromNowDuring = 3600000;

        if (type === "reinvest") {
            if (displayType !== ALL && displayType !== REINVEST) {
                return null;
            }

            return (
                <div className="box-5 box-5-received">
                    <a target={"_blank"} href={"https://explorer.ouroboros-crypto.com/hash/" + tx.hash}><h3><Translate id={"transaction.reinvest"} /> <span>#{tx.height}</span></h3></a>

                    <div className="clear"></div>

                    <p className="p5-1"><span>+{formatCoins(tx.posmined?.amount)} {tx?.amount?.symbol}</span><br />{convertFormatCoinsToUsd(tx?.posmined?.amount, rate[tx?.amount?.symbol])}$</p>
                    <p className="p5-2"><span>
                    <Moment
                        locale={this.props.activeLanguage?.code}
                        format="DD MMM YYYY"
                        fromNowDuring={fromNowDuring}
                    >
                            {timestamp}
                        </Moment></span><br /><Translate id={"transaction.datetime"} /></p>
                    <div className="clear"></div>
                    <p className="p5-3"><Translate id={"transaction.sender"} />:<br /><span>{sender}</span>
                    </p>
                    <div className="clear"></div>
                </div>
            );
        }

        if (type === "reward") {
            if (displayType !== ALL) {
                return null;
            }

            return (
                <div className="box-5 box-5-received">
                    <a target={"_blank"} href={"https://explorer.ouroboros-crypto.com/hash/" + tx.hash}><h3><Translate id={"delegation.reward"} /> <span>#{tx.height}</span></h3></a>

                    <div className="clear"></div>

                    <p className="p5-1">
                      <span>+{formatCoins(tx?.amount?.amount)} {tx?.amount?.symbol}</span><br />
                      {convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])}$
                    </p>
                    <p className="p5-2"><span>
                    <Moment
                        locale={this.props.activeLanguage?.code}
                        format="DD MMM YYYY"
                        fromNowDuring={fromNowDuring}
                    >
                            {timestamp}
                        </Moment></span><br /><Translate id={"transaction.datetime"} /></p>
                    <div className="clear"></div>
                    <p className="p5-3"><Translate id={"transaction.sender"} />:<br /><span>{sender}</span>
                    </p>
                    <div className="clear"></div>
                </div>
            );
        }

      if (type === "delegate") {
        if (displayType !== ALL) {
          return null;
        }

        return (
          <div className="box-5 box-5-sent">
            <a target={"_blank"} href={"https://explorer.ouroboros-crypto.com/hash/" + tx.hash}><h3><Translate id={"delegation.delegation"} /> <span>#{tx.height}</span></h3></a>

            <div className="clear"></div>
            <p className="p5-1"><span>+{formatCoins(tx?.amount?.amount)} {tx?.amount?.symbol}</span><br />{convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])}$</p>
            <p className="p5-2"><span>
                    <Moment
                      locale={this.props.activeLanguage.code}
                      format="DD MMM YYYY"
                      fromNowDuring={fromNowDuring}
                    >
                            {timestamp}
                        </Moment></span><br /><Translate id={"transaction.datetime"} /></p>
            <div className="clear"></div>
            <p className="p5-3"><Translate id={"transaction.sender"} />:<br /><span>{sender}</span>
            </p>
            <div className="clear"></div>
          </div>
        );
      }

      if (type === "undelegate") {
        if (displayType !== ALL) {
          return null;
        }

        return (
          <div className="box-5 box-5-received">
            <a target={"_blank"} href={"https://explorer.ouroboros-crypto.com/hash/" + tx.hash}><h3><Translate id={"delegation.delegation"} /> <span>#{tx.height}</span></h3></a>

            <div className="clear"></div>
            <p className="p5-1"><span>+{formatCoins(tx?.amount?.amount)} {tx?.amount?.symbol}</span><br />{convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])}$</p>
            <p className="p5-2"><span>
                    <Moment
                      locale={this.props.activeLanguage.code}
                      format="DD MMM YYYY"
                      fromNowDuring={fromNowDuring}
                    >
                            {timestamp}
                        </Moment></span><br /><Translate id={"transaction.datetime"} /></p>
            <div className="clear"></div>
            <p className="p5-3"><Translate id={"transaction.sender"} />:<br /><span>{sender}</span>
            </p>
            <div className="clear"></div>
          </div>
        );
      }

        if (type === "transfer") {
          if (isFrom && (displayType === ALL || displayType === FROM_ADDRESS)) {
            return (
              <div className={"box-5 box-5-sent"}>
                <a target={"_blank"} href={"https://explorer.ouroboros-crypto.com/hash/" + tx.hash}>
                  <h3>{isFrom ? <Translate id={"transaction.sent"}/> : <Translate id={"transaction.received"}/>}
                    <span>#{tx?.height}</span></h3></a>

                <div className="clear"></div>
                  
                <p className="p5-1">
                  <span>{isFrom ? "-" : "+"}{formatCoins(tx?.amount?.amount)} {tx?.amount?.symbol}</span><br/>{convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])}$
                </p>
                <p className="p5-2"><span>
                    <Moment
                      locale={this.props.activeLanguage?.code}
                      format="DD MMM YYYY"
                      fromNowDuring={fromNowDuring}
                    >
                            {timestamp}
                        </Moment></span><br/><Translate id={"transaction.datetime"}/></p>
                <div className="clear"></div>
                <div className="clear"></div>
                <p className="p5-3"><Translate id={"transaction.sender"}/>:<br/><span>{sender}</span>
                </p>
                <div className="clear"></div>
              </div>
            );
          }
          if (isReceived && (displayType === ALL || displayType === TO_ADDRESS)) {
            return (
              <div className={"box-5 box-5-received"}>
                <a target={"_blank"} href={"https://explorer.ouroboros-crypto.com/hash/" + tx.hash}>
                  <h3>{isFrom && !isReceived ? <Translate id={"transaction.sent"}/> : <Translate id={"transaction.received"}/>}
                    <span>#{tx?.height}</span></h3></a>

                <div className="clear"></div>

                <p className="p5-1">
                  <span>{isFrom ? "-" : "+"}{formatCoins(tx?.amount?.amount)} {tx?.amount?.symbol}</span><br/>{convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])}$
                </p>
                <p className="p5-2"><span>
                    <Moment
                      locale={this.props.activeLanguage.code}
                      format="DD MMM YYYY"
                      fromNowDuring={fromNowDuring}
                    >
                            {timestamp}
                        </Moment></span><br/><Translate id={"transaction.datetime"}/></p>
                <div className="clear"></div>
                <div className="clear"></div>
                <p className="p5-3"><Translate id={"transaction.sender"}/>:<br/><span>{sender}</span>
                </p>
                <div className="clear"></div>
              </div>
            );
          }

          if (type !== 'transfer' && type !== 'reward' && type !== 'reinvest' && type !== 'delegate' && type !== 'undelegate') {
            return (
              <div className={isFrom ? "box-5 box-5-sent" : "box-5 box-5-received"}>
                <a target={"_blank"} href={"https://explorer.ouroboros-crypto.com/hash/" + tx?.hash}>
                  <h3>{type}
                    <span>#{tx?.height}</span></h3></a>

                <div className="clear"></div>

                <p className="p5-1">
                  <span>{isFrom ? "-" : "+"}{formatCoins(tx?.amount?.amount)} {tx?.amount?.symbol}</span><br/>{convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])}$
                </p>
                <p className="p5-2"><span>
                    <Moment
                      locale={this.props.activeLanguage?.code}
                      format="DD MMM YYYY"
                      fromNowDuring={fromNowDuring}
                    >
                            {timestamp}
                        </Moment></span><br/><Translate id={"transaction.datetime"}/></p>
                <div className="clear"></div>
                <div className="clear"></div>
                <p className="p5-3"><Translate id={"transaction.sender"}/>:<br/><span>{sender}</span>
                </p>
                <div className="clear"></div>
              </div>
            );
          }
          return null;
        }
        return null;
    }
}

export default withLocalize(TransactionItem);
