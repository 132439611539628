import {
	sendMnemoTxError,
	sendMnemoTxPending,
	sendMnemoTxSuccess,
} from "../actions/tx";
import {
	generateAccountFromMnemonic,
	generatePushableTransaction,
} from "../utils/crypto";
import { getAccountInfo, broadcastTx } from "../utils/blockchain";
const { DirectSecp256k1HdWallet } = require("@cosmjs/proto-signing");
const {
	StargateClient,
	SigningStargateClient,
	assertIsBroadcastTxSuccess,
} = require("@cosmjs/stargate");

const rpcEndpoint = "https://rpc.ouroboros-crypto.com"; // Replace with your blockchain RPC endpoint
const chainId = "ouroboros"; // Replace with your chain ID
const denom = "ouro"; // Replace with your token denomination

export default function sendTxAction(user, to, amount, symbol) {
	return (dispatch, getState) => {
		dispatch(sendMnemoTxPending());

		// Step 1: Initialize the wallet
		DirectSecp256k1HdWallet.fromMnemonic(user.mnemonic, {
			prefix: "ouro",
		}).then((wallet) => {
			wallet.getAccounts().then((accounts) => {
				const account = accounts[0];

				let fee = "10000";

				if (parseFloat(amount) > 4) {
					fee = (parseFloat(amount) * 1e6 * 0.0025).toString();
				}

				console.log("fee", fee);

				// Step 2: Connect to the blockchain
				SigningStargateClient.connectWithSigner(rpcEndpoint, wallet).then(
					(client) => {
						const gasLimit = 95000000000;
						const feeAmount = {
							amount: [
								{
									denom: denom,
									amount: fee, // Fee in smallest denomination
								},
							],
							gas: gasLimit.toString(),
						};
						// Step 3: Build the transaction message
						const sendAmount = {
							denom: denom,
							amount: (parseFloat(amount) * 1e6).toString(), // 1 unit (in smallest denomination, e.g., 1 ATOM = 1_000_000 uatom)
						};

						const msgSend = {
							fromAddress: account.address,
							toAddress: to,
							amount: [sendAmount],
						};

						const msgAny = {
							typeUrl: "/cosmos.bank.v1beta1.MsgSend", // The type URL for the MsgSend
							value: msgSend,
						};

						// Step 4: Sign and broadcast the transaction
						client
							.signAndBroadcast(
								account.address,
								[msgAny],
								feeAmount,
								"Coins transfer",
							)
							.then((result) => {
								dispatch(sendMnemoTxSuccess(result));
								console.log("Transaction successful:", result.transactionHash);
							});
					},
				);
			});
		});
	};
}